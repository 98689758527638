<template>
  <v-container fluid>
    <div v-if="!detailsView">
      <v-select
        v-if="$vuetify.breakpoint.mobile"
        dense
        :items="projectList"
        item-text="name"
        item-value="id"
        v-model="selselectedItem"
        :label="$t('message.Projects')"
      />
      <v-row>
        <v-col>
          <h2>{{ project.name }}</h2>
        </v-col>
      </v-row>
      <v-row no-gutters align="center" :class="$vuetify.breakpoint.mobile ? 'mb-4' : 'mb-4'">
        <v-col cols="12" md="5" align="right" v-if="HasFullAccessPermissions">
          <v-row no-gutters justify="center" align="center">
            <v-col cols="12" md="9">
              <v-col cols="12" :class="$vuetify.breakpoint.mobile ? 'pa-0' : ''"> </v-col>
            </v-col>

            <v-col cols="12" md="3" class="text-right"> </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row no-gutters align="center" class="" v-if="!$vuetify.breakpoint.mobile">
        <!-- <v-row no-gutters>
        <v-col cols="auto" style="min-width: 55px" class="text-center">{{ $t('RiskListHeaders.RiskId') }}</v-col>
        <v-col cols="2" class="pl-8">{{ $t('RiskDetails.RiskTitle') }}</v-col>
        <v-col cols="1" class="text-center">{{ $t('RiskListHeaders.Probability') }}</v-col>
        <v-col cols="1" class="text-center">{{ $t('RiskListHeaders.TimePlan') }}</v-col>
        <v-col cols="1" class="text-center">{{ $t('RiskListHeaders.Expenses') }}</v-col>
        <v-col cols="1" class="text-center">{{ $t('RiskListHeaders.Scope') }}</v-col>
        <v-col cols="1" class="text-center">{{ $t('RiskListHeaders.Resources') }}</v-col>
        <v-col cols="1" class="text-center">{{ $t('RiskListHeaders.Status') }}</v-col>
        <v-col cols="1" class="text-center">{{ $t('RiskListHeaders.Owner') }}</v-col>
        <v-col cols="1" class="text-center">{{ $t('ProjectListHeaders.Updated') }}</v-col>
      </v-row> -->
        <v-col cols="2" class="" style="">
          <v-text-field
            :color="$vuetify.theme.dark ? 'black' : 'white'"
            depressed
            hide-details
            outlined
            dense
            :label="$t('message.New')"
            v-model="riskName"
            background-color="searchField"
            v-show="expandNewRisk"
          />
          <v-text-field
            :color="$vuetify.theme.dark ? 'black' : 'white'"
            depressed
            hide-details
            outlined
            dense
            :label="$t('message.Search')"
            v-model="Search"
            background-color="searchField"
            v-show="expandSearch"
          />
        </v-col>
        <v-col cols="1" class="d-flex justify-start">
          <v-icon class="mx-2" @click="changeSearchNewRisk(false)">mdi-plus</v-icon>
          <v-icon class="mx-2" @click="changeSearchNewRisk(true)">mdi-magnify</v-icon>
        </v-col>

        <v-col cols="1" class="text-center pl-8">
          <v-card class="d-flex" width="0" flat tile>
            <v-card class="px-2 py-1 rounded" outlined tile height="30" max-width="40" :color="$util.colorize(CalculateAvarageOldProbility)">
              {{ CalculateAvarageOldProbility }}
            </v-card>
            <v-icon class="mx-1">mdi-arrow-right</v-icon>
            <v-card class="px-2 py-1 rounded" outlined tile height="30" max-width="40" :color="$util.colorize(CalculateAvarageOldProbility)">
              {{ CalculateAvarageNewProbility }}
            </v-card>
          </v-card>
        </v-col>
        <v-col cols="1" class="pl-7">
          <v-card class="d-flex" width="0" flat tile>
            <v-card class="px-2 py-1 rounded" outlined tile height="30" max-width="40" :color="$util.colorize(CalculateAvarageOldProbility)">
              {{ CalculateAvarageOldTimeLine }}
            </v-card>
            <v-icon class="mx-1">mdi-arrow-right</v-icon>
            <v-card class="px-2 py-1 rounded" outlined tile height="30" max-width="40" :color="$util.colorize(CalculateAvarageOldProbility)">
              {{ CalculateAvarageNewTimeLine }}
            </v-card>
          </v-card>
        </v-col>
        <v-col cols="1" class="pl-6">
          <v-card class="d-flex" width="0" flat tile>
            <v-card class="px-2 py-1 rounded" outlined tile height="30" max-width="40" :color="$util.colorize(CalculateAvarageOldProbility)">
              {{ CalculateAvarageOldExpenses }}
            </v-card>
            <v-icon class="mx-1">mdi-arrow-right</v-icon>
            <v-card class="px-2 py-1 rounded" outlined tile height="30" max-width="40" :color="$util.colorize(CalculateAvarageOldProbility)">
              {{ CalculateAvarageNewExpenses }}
            </v-card>
          </v-card>
        </v-col>
        <v-col cols="1" class="pl-7">
          <v-card class="d-flex" width="0" flat tile>
            <v-card class="px-2 py-1 rounded" outlined tile height="30" max-width="40" :color="$util.colorize(CalculateAvarageOldProbility)">
              {{ CalculateAvarageOldScope }}
            </v-card>
            <v-icon class="mx-1">mdi-arrow-right</v-icon>
            <v-card class="px-2 py-1 rounded" outlined tile height="30" max-width="40" :color="$util.colorize(CalculateAvarageOldProbility)">
              {{ CalculateAvarageNewScope }}
            </v-card>
          </v-card>
        </v-col>
        <v-col cols="1" class="pl-6">
          <v-card class="d-flex" width="0" flat tile>
            <v-card class="px-2 py-1 rounded" outlined tile height="30" max-width="40" :color="$util.colorize(CalculateAvarageOldProbility)">
              {{ CalculateAvarageOldResource }}
            </v-card>
            <v-icon class="mx-1">mdi-arrow-right</v-icon>
            <v-card class="px-2 py-1 rounded" outlined tile height="30" max-width="40" :color="$util.colorize(CalculateAvarageOldProbility)">
              {{ CalculateAvarageNewResource }}
            </v-card>
          </v-card>
        </v-col>
        <v-col cols="1" class="pl-5">
          <v-card class="d-flex" width="0" flat tile>
            <v-card class="px-2 py-1 rounded" outlined tile height="30" max-width="40" :color="$util.colorize(CalculateAvarageOldProbility)">
              {{ CalculateAvarageOldStatus }}
            </v-card>
            <v-icon class="mx-1">mdi-arrow-right</v-icon>
            <v-card class="px-2 py-1 rounded" outlined tile height="30" max-width="40" :color="$util.colorize(CalculateAvarageOldProbility)">
              {{ CalculateAvarageNewStatus }}
            </v-card>
          </v-card>
        </v-col>

        <v-col cols="3" class="d-flex justify-end" style="">
          <!-- <v-select
            class="text-right"
            style=""
            @change="updateActiveDisabledRisks()"
            v-model="riskActiveselected"
            dense
            solo
            hide-details
            :items="[
              {
                text: $t('RiskListHeaders.ShowAll'),
                value: 1,
              },
              {
                text: $t('RiskListHeaders.HideActiveRisksButton'),
                value: 2,
              },
              {
                text: $t('RiskListHeaders.HideRisksButton'),
                value: 3,
              },
            ]"
          >
          </v-select> -->

          <!-- <v-radio-group v-model="row" row class="float-right mr-n3">
            <span class="mr-3">Skjul</span>
            <v-radio @change="updateActiveDisabledRisks(1)" label="2 inaktive" value="radio-1"></v-radio>
            <v-radio @change="updateActiveDisabledRisks(2)" label="3 aktive" value="radio-2"></v-radio>
          </v-radio-group> -->

          <span class="mr-2 mt-5">{{ $t('RiskHeaders.Hide') }}</span>
          <v-checkbox
            v-model="riskActiveselected"
            @change="updateActiveDisabledRisks()"
            class="mr-2"
            :label="disabletCount + ' ' + this.$t('RiskHeaders.Inactive')"
            color="grey"
            ref="chkActiveRisks"
          ></v-checkbox>
          <v-checkbox
            v-model="riskDisabletselected"
            @change="updateActiveDisabledRisks()"
            :label="activeCount + ' ' + this.$t('RiskHeaders.Active')"
            color="grey"
            ref="chkDisabletRisks"
            class=""
          ></v-checkbox>
        </v-col>
      </v-row>

      <v-data-iterator
        :single-expand="singleExpand"
        :search="Search"
        :expanded.sync="expanded"
        :items="RiskListItems"
        sort-by="riskNumber"
        sort-desc
        :footer-props="footerProps"
        :items-per-page="15"
      >
        <template #header>
          <v-toolbar height="40" v-if="!$vuetify.breakpoint.mobile" class="pa-0">
            <v-row no-gutters>
              <v-col cols="1" style="" class="text-center">{{ $t('RiskListHeaders.RiskId') }}</v-col>
              <v-col cols="2" class="">{{ $t('RiskDetails.RiskTitle') }}</v-col>
              <v-col cols="1" class="text-center">{{ $t('RiskListHeaders.Probability') }}</v-col>
              <v-col cols="1" class="text-center">{{ $t('RiskListHeaders.TimePlan') }}</v-col>
              <v-col cols="1" class="text-center">{{ $t('RiskListHeaders.Expenses') }}</v-col>
              <v-col cols="1" class="text-center">{{ $t('RiskListHeaders.Scope') }}</v-col>
              <v-col cols="1" class="text-center">{{ $t('RiskListHeaders.Resources') }}</v-col>
              <v-col cols="1" class="text-center">{{ $t('RiskListHeaders.Status') }}</v-col>
              <v-col cols="1" class="text-center">{{ $t('RiskListHeaders.Owner') }}</v-col>
              <!-- <v-col cols="1" class="text-center">Omkostning</v-col> -->
              <v-col cols="1" class="text-center">{{ $t('ProjectListHeaders.Updated') }}</v-col>
            </v-row>
          </v-toolbar>
          <v-toolbar v-if="$vuetify.breakpoint.mobile" style="min-height: 50px"> </v-toolbar>
        </template>
        <template #default="{ items, isExpanded }">
          <v-row no-gutters class="mt-2">
            <v-col cols="12" class="mb-2" v-for="(item, index) in items" :key="index">
              <v-hover v-slot="{ hover }">
                <v-expand-transition>
                  <v-card tile :color="hover ? 'dataTableRowHover' : 'dataTableRow'">
                    <v-row @click="expandRow(item)" align="center" no-gutters class="px-4 py-2 text-body-2">
                      <v-col class="text-center" cols="1">{{ item.riskNumber }}</v-col>
                      <v-col class="" style="" cols="2">{{ item.title }}</v-col>
                      <v-col cols="1" style="">
                        <v-row no-gutters class="">
                          <v-col>
                            <v-card class="mx-auto text-center pt-4" height="45" width="45" :color="$util.colorize(item.probability)">
                              {{ item.probability }}
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="1" md="1" class="text-center">
                        <v-row no-gutters align="center" justify="center">
                          <v-avatar :color="$util.colorizeByMatrix(item.timeLine, project.riskAppetite, item.probability)" size="45">
                            <span>{{ item.timeLine * item.probability }} </span>
                          </v-avatar>
                        </v-row>
                      </v-col>
                      <v-col class="text-center" cols="1" md="1">
                        <v-row no-gutters align="center" justify="center">
                          <v-avatar :color="$util.colorizeByMatrix(item.expenses, project.riskAppetite, item.probability)" size="45">
                            <span>{{ item.expenses * item.probability }} </span>
                          </v-avatar>
                        </v-row>
                      </v-col>
                      <v-col class="text-ceneter pl-1" cols="1">
                        <v-row no-gutters align="center" justify="center">
                          <v-avatar :color="$util.colorizeByMatrix(item.scope, project.riskAppetite, item.probability)" size="45">
                            <span>{{ item.scope * item.probability }} </span>
                          </v-avatar>
                        </v-row>
                      </v-col>
                      <v-col class="text-center" cols="1">
                        <v-row no-gutters align="center" justify="center">
                          <v-avatar :color="$util.colorizeByMatrix(item.resource, project.riskAppetite, item.probability)" size="45">
                            <span>{{ item.resource * item.probability }} </span>
                          </v-avatar>
                        </v-row>
                      </v-col>
                      <v-col class="text-center" cols="1">
                        <v-row no-gutters align="center" justify="center">
                          <v-col class="flex-colum">
                            <v-card
                              class="mx-auto d-flex"
                              height="45"
                              width="100"
                              :color="
                                item.probability == 0
                                  ? '#D6D8D9'
                                  : item.probability == 6
                                  ? '#8C8D90'
                                  : $util.colorizeByMatrix(
                                      $util.consequenceCalculator(item.timeLine, item.expenses, item.scope, item.resource, item.probability),
                                      project.riskAppetite,
                                      item.probability
                                    )
                              "
                            >
                              <div class="mx-auto my-auto">
                                {{ $util.consequenceCalculator(item.timeLine, item.expenses, item.scope, item.resource, item.probability) }}
                              </div>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col class="text-center" cols="1">
                        {{ item.owner.name }}
                      </v-col>
                      <!-- <v-col cols="1" class="text-center"><div>000.000</div> </v-col> -->
                      <v-col class="text-center" cols="1">
                        {{ $util.formatDate(item.updated, false) }}
                      </v-col>
                      <v-col cols="1" class="text-right">
                        <v-btn icon plain large @click="showRiskDetails(item)" :title="$t('ButtonsLabel.SeeDetails')"
                          ><v-icon>mdi-dots-horizontal</v-icon></v-btn
                        >
                      </v-col>
                    </v-row>

                    <v-row
                      v-if="$vuetify.breakpoint.mobile"
                      @click="isExpanded(item) ? closeRow(item) : expandRow(item)"
                      align="center"
                      no-gutters
                      class="py-2 px-2 text-body-2"
                    >
                      <v-col cols="4"> {{ $t('RiskListHeaders.RiskId') }} : </v-col>

                      <v-col cols="8" class="text-right pb-2 pr-4">
                        {{ item.riskNumber }}
                      </v-col>
                      <v-col cols="4"> {{ $t('RiskDetails.RiskTitle') }} : </v-col>

                      <v-col cols="8" class="text-right pb-2 pr-4">
                        {{ item.title }}
                      </v-col>

                      <v-col cols="4">{{ $t('RiskListHeaders.Owner') }} :</v-col>

                      <v-col cols="8" class="text-right pb-2 pr-4">
                        {{ item.owner.name }}
                      </v-col>

                      <v-col cols="4">{{ $t('ProjectListHeaders.Updated') }} :</v-col>

                      <v-col cols="8" class="text-right mb-4 pr-4">
                        {{ $util.formatDate(item.updated, false) }}
                      </v-col>

                      <v-col cols="4"> {{ $t('RiskListHeaders.Probability') }} : </v-col>

                      <v-col cols="8" class="mb-4">
                        <v-row no-gutters align="center" justify="end" class="text-right">
                          <color-range-field class="mr-4 p" :value="isExpanded(item) ? activeItem.probability : item.probability" />
                        </v-row>
                      </v-col>

                      <v-col cols="4"> {{ $t('RiskListHeaders.TimePlan') }} : </v-col>

                      <v-col cols="8" class="mb-4">
                        <v-row no-gutters align="center" justify="end" class="text-right pr-4">
                          <v-avatar
                            :color="
                              $util.colorizeByProperbility(
                                isExpanded(item) ? activeItem.timeLine : item.timeLine,
                                isExpanded(item) ? activeItem.probability : item.probability
                              )
                            "
                            size="30"
                          >
                            <span
                              v-if="
                                (isExpanded(item) ? activeItem.probability : item.probability) != 0 &&
                                (isExpanded(item) ? activeItem.probability : item.probability) != 6
                              "
                            >
                              {{ (isExpanded(item) ? activeItem.timeLine : item.timeLine) * (isExpanded(item) ? activeItem.probability : item.probability) }}
                            </span>
                          </v-avatar>
                        </v-row>
                      </v-col>

                      <v-col cols="4"> {{ $t('RiskListHeaders.Expenses') }} : </v-col>

                      <v-col cols="8" class="mb-4">
                        <v-row no-gutters align="center" justify="end" class="text-right pr-4">
                          <v-avatar
                            :color="
                              $util.colorizeByProperbility(
                                isExpanded(item) ? activeItem.expenses : item.expenses,
                                isExpanded(item) ? activeItem.probability : item.probability
                              )
                            "
                            size="30"
                          >
                            <span
                              v-if="
                                (isExpanded(item) ? activeItem.probability : item.probability) != 0 &&
                                (isExpanded(item) ? activeItem.probability : item.probability) != 6
                              "
                            >
                              {{ (isExpanded(item) ? activeItem.expenses : item.expenses) * (isExpanded(item) ? activeItem.probability : item.probability) }}
                            </span>
                          </v-avatar>
                        </v-row>
                      </v-col>
                      <v-col cols="4"> {{ $t('RiskListHeaders.Scope') }} : </v-col>

                      <v-col cols="8" class="mb-4">
                        <v-row no-gutters align="center" justify="end" class="text-right pr-4">
                          <v-avatar
                            :color="
                              $util.colorizeByProperbility(
                                isExpanded(item) ? activeItem.scope : item.expenses,
                                isExpanded(item) ? activeItem.probability : item.probability
                              )
                            "
                            size="30"
                          >
                            <span
                              v-if="
                                (isExpanded(item) ? activeItem.probability : item.probability) != 0 &&
                                (isExpanded(item) ? activeItem.probability : item.probability) != 6
                              "
                            >
                              {{ (isExpanded(item) ? activeItem.scope : item.scope) * (isExpanded(item) ? activeItem.probability : item.probability) }}
                            </span>
                          </v-avatar>
                        </v-row>
                      </v-col>
                      <v-col cols="4"> {{ $t('RiskListHeaders.Resources') }} : </v-col>

                      <v-col cols="8" class="mb-4">
                        <v-row no-gutters align="center" justify="end" class="text-right pr-4">
                          <v-avatar
                            :color="
                              $util.colorizeByProperbility(
                                isExpanded(item) ? activeItem.resource : item.resource,
                                isExpanded(item) ? activeItem.probability : item.probability
                              )
                            "
                            size="30"
                          >
                            <span
                              v-if="
                                (isExpanded(item) ? activeItem.probability : item.probability) != 0 &&
                                (isExpanded(item) ? activeItem.probability : item.probability) != 6
                              "
                            >
                              {{ (isExpanded(item) ? activeItem.resource : item.resource) * (isExpanded(item) ? activeItem.probability : item.probability) }}
                            </span>
                          </v-avatar>
                        </v-row>
                      </v-col>

                      <v-col cols="4"> {{ $t('RiskListHeaders.Status') }} : </v-col>

                      <v-col cols="8" class="mb-4">
                        <v-row no-gutters align="center" justify="end">
                          <v-card class="d-flex mt-2 mr-4" height="30" width="100" :color="$util.colorize(activeOrItemStatus(isExpanded(item), item), true)">
                            <div class="mx-auto my-auto">
                              {{ activeOrItemStatus(isExpanded(item), item) }}
                            </div>
                          </v-card>
                        </v-row>
                      </v-col>
                    </v-row>

                    <v-expand-transition>
                      <v-row v-if="isExpanded(item) && !$vuetify.breakpoint.mobile" no-gutters class="px-4 py-2 text-body-2 grey lighten-3">
                        <v-col cols="12">
                          <!-- <v-row no-gutters align="center">
                            <v-col cols="auto" class="text-left mt-5 pl-3" style="min-width: 55px" md="auto" @click="closeRow">
                              {{ activeItem.riskNumber }}
                            </v-col>
                            <v-col class="pl-8 font-weight-bold mt-5" cols="2" @click="closeRow">
                              {{ activeItem.title }}
                            </v-col>

                            <v-col cols="1" md="1">
                              <v-row no-gutters align="center" justify="center">
                                <v-col>
                                  <v-card class="mx-auto text-center pt-4 mt-1" height="45" width="45" :color="$util.colorize(activeItem.probability)">
                                    {{ activeItem.probability }}
                                  </v-card>
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col cols="1" md="1" class="text-center" style="">
                              <v-row no-gutters align="center" justify="center" style="" class="mt-1">
                                <v-col class="text-center">
                                  <v-avatar
                                    :color="
                                      activeItem.probability == 0
                                        ? '#D6D8D9'
                                        : activeItem.probability == 6
                                        ? '#8C8D90'
                                        : $util.colorizeByMatrix(activeItem.timeLine, project.riskAppetite, activeItem.probability)
                                    "
                                    size="45"
                                  >
                                    <span
                                      >{{ activeItem.probability == 0 || activeItem.probability == 6 ? '' : activeItem.timeLine * activeItem.probability }}
                                    </span>
                                  </v-avatar>
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col cols="1" md="1" class="text-center">
                              <v-row no-gutters align="center" justify="center" class="mt-1" style="">
                                <v-avatar
                                  :color="
                                    activeItem.probability == 0
                                      ? '#D6D8D9'
                                      : activeItem.probability == 6
                                      ? '#8C8D90'
                                      : $util.colorizeByMatrix(activeItem.expenses, project.riskAppetite, activeItem.probability)
                                  "
                                  size="45"
                                >
                                  <span
                                    >{{ activeItem.probability == 0 || activeItem.probability == 6 ? '' : activeItem.expenses * activeItem.probability }}
                                  </span>
                                </v-avatar>
                              </v-row>
                            </v-col>
                            <v-col cols="1" md="1" class="text-center pl-1">
                              <v-row no-gutters align="center" justify="center" style="" class="mt-1">
                                <v-avatar
                                  :color="
                                    activeItem.probability == 0
                                      ? '#D6D8D9'
                                      : activeItem.probability == 6
                                      ? '#8C8D90'
                                      : $util.colorizeByMatrix(activeItem.scope, project.riskAppetite, activeItem.probability)
                                  "
                                  size="45"
                                >
                                  <span
                                    >{{ activeItem.probability == 0 || activeItem.probability == 6 ? '' : activeItem.scope * activeItem.probability }}
                                  </span>
                                </v-avatar>
                              </v-row>
                            </v-col>
                            <v-col cols="1" md="1" class="text-center pl-1">
                              <v-row no-gutters align="center" justify="center" style="" class="mt-1">
                                <v-avatar
                                  :color="
                                    activeItem.probability == 0
                                      ? '#D6D8D9'
                                      : activeItem.probability == 6
                                      ? '#8C8D90'
                                      : $util.colorizeByMatrix(activeItem.resource, project.riskAppetite, activeItem.probability)
                                  "
                                  size="45"
                                >
                                  <span
                                    >{{ activeItem.probability == 0 || activeItem.probability == 6 ? '' : activeItem.resource * activeItem.probability }}
                                  </span>
                                </v-avatar>
                              </v-row>
                            </v-col>
                            <v-col cols="1" md="1" class="text-center" @click="closeRow">
                              <v-row no-gutters align="center" justify="center">
                                <v-col class="flex-column">
                                  <v-card
                                    class="mx-auto d-flex mt-1"
                                    height="45"
                                    width="100"
                                    :color="
                                      activeItem.probability == 0
                                        ? '#D6D8D9'
                                        : activeItem.probability == 6
                                        ? '#8C8D90'
                                        : $util.colorizeByMatrix(
                                            $util.consequenceCalculator(
                                              activeItem.timeLine,
                                              activeItem.expenses,
                                              activeItem.scope,
                                              activeItem.resource,
                                              activeItem.probability
                                            ),
                                            project.riskAppetite,
                                            activeItem.probability
                                          )
                                    "
                                  >
                                    <div class="mx-auto my-auto">
                                      {{
                                        $util.consequenceCalculator(
                                          activeItem.timeLine * activeItem.probability,
                                          activeItem.expenses * activeItem.probability,
                                          activeItem.scope * activeItem.probability,
                                          activeItem.resource * activeItem.probability,
                                          activeItem.probability
                                        )
                                      }}
                                    </div>
                                  </v-card>
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col cols="1" class="mt-4 text-center" style="max-width: 200px" @click="closeRow">
                              {{ item.owner.name }}
                            </v-col>
                            <v-col cols="1" class="text-center mx-auto mt-4">
                              <div>000.000</div>
                            </v-col>
                            <v-col class="text-center mx-auto mt-4" cols="1" md="1" @click="closeRow">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <span v-bind="attrs" v-on="on"> {{ $util.formatDate(item.updated, false) }}</span>
                                </template>
                                <span> {{ $util.formatDate(item.updated, true) }}</span>
                              </v-tooltip>
                            </v-col>
                            <v-col cols="" class="d-flex flex-colum mt-1 mr-n3">
                              <v-col cols="12" align="right">
                                <v-btn plain icon large @click="showRiskDetails(item)" :title="$t('ButtonsLabel.SeeDetails')"
                                  ><v-icon>mdi-dots-horizontal</v-icon></v-btn
                                >
                              </v-col>
                            </v-col>
                          </v-row> -->
                          <v-divider class="mb-3" style="border-width: 2px"></v-divider>
                          <v-row no-gutters class="">
                            <v-col cols="3" class="pl-3 flex-column" style="">
                              <v-row no-gutters>
                                <v-col>
                                  <div class="font-weight-bold">Beskrivelse</div>
                                  <v-textarea
                                    dense
                                    outlined
                                    flat
                                    background-color="#ffffff"
                                    solo
                                    no-resize
                                    name="input-7-4"
                                    label="Solo textarea"
                                    value="orem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500"
                                  >
                                  </v-textarea>
                                </v-col>
                              </v-row>
                              <v-row no-gutters>
                                <v-col>
                                  <div class="font-weight-bold">Indtræffer tidligst</div>
                                  <v-text-field
                                    class="mr-2"
                                    style="min-width: 110px"
                                    dense
                                    outlined
                                    background-color="#ffffff"
                                    value="1.juni 2022"
                                  ></v-text-field>
                                </v-col>
                                <v-col>
                                  <div class="font-weight-bold">Risikotype</div>
                                  <v-select
                                    style=""
                                    flat
                                    v-model="riskTypeSelectedItem"
                                    :items="['Interessenter']"
                                    dense
                                    outlined
                                    background-color="#ffffff"
                                  ></v-select>
                                </v-col>
                              </v-row>
                              <v-row no-gutters>
                                <v-col class="d-flex justify-end">
                                  <span class="font-weight-bold mt-6 mr-2">Vis historik</span>
                                  <v-checkbox class=""></v-checkbox>
                                </v-col>
                                <v-col>
                                  <div>Historik</div>
                                  <v-select class="" flat v-model="riskHistorySelection" :items="currrentRiskHistory" dense solo @change="setItem()"></v-select>
                                </v-col>
                              </v-row>
                            </v-col>

                            <v-col cols="1" class="flex-column">
                              <p class="text-center">{{ $t('RiskListHeaders.Probability') }}</p>

                              <DoubleSlider
                                class=""
                                v-model="activeItem.probability"
                                :previousValue="previousRisk.probability"
                                :readonly="!HasReadWritePermissions"
                                :showHistory="true"
                                :largeSlider="true"
                                :maxValue="6"
                              />
                            </v-col>
                            <v-col cols="1" class="flex-column">
                              <p class="text-center">{{ $t('RiskListHeaders.TimePlan') }}</p>
                              <p class="text-center font-weight-bold">25 d.</p>
                              <DoubleSlider
                                class=""
                                v-model="activeItem.timeLine"
                                :previousValue="previousRisk.timeLine"
                                :readonly="!HasReadWritePermissions"
                                :showHistory="true"
                                :disabled="activeItem.probability == 0 || activeItem.probability == 6 ? true : false"
                                :largeSlider="false"
                                :maxValue="5"
                              />
                              <v-text-field class="mx-2" flat label="Korriger" dense solo outlined></v-text-field>
                            </v-col>
                            <v-col cols="1" class="flex-column">
                              <p class="text-center">{{ $t('RiskListHeaders.Expenses') }}</p>
                              <p class="text-center">9.999.500 kr.</p>
                              <DoubleSlider
                                class=""
                                v-model="activeItem.expenses"
                                :previousValue="previousRisk.expenses"
                                :readonly="!HasReadWritePermissions"
                                :showHistory="true"
                                :disabled="activeItem.probability == 0 || activeItem.probability == 6 ? true : false"
                                :largeSlider="false"
                                :maxValue="5"
                              />
                              <v-text-field class="mx-2" flat label="Korriger" dense solo outlined value="125.000 kr."></v-text-field>
                            </v-col>
                            <v-col cols="1" class="flex-column">
                              <p class="text-center">{{ $t('RiskListHeaders.Scope') }}</p>
                              <p class="text-center font-weight-bold">0 %</p>
                              <DoubleSlider
                                class=""
                                v-model="activeItem.scope"
                                :previousValue="previousRisk.scope"
                                :readonly="!HasReadWritePermissions"
                                :showHistory="true"
                                :disabled="activeItem.probability == 0 || activeItem.probability == 6 ? true : false"
                                :largeSlider="false"
                                :maxValue="5"
                              />
                              <v-text-field class="mx-2" flat label="Korriger" dense solo outlined></v-text-field>
                            </v-col>
                            <v-col cols="1" class="flex-column">
                              <p class="text-center">{{ $t('RiskListHeaders.Resources') }}</p>
                              <p class="text-center">1000 t.</p>
                              <DoubleSlider
                                class=""
                                v-model="activeItem.resource"
                                :previousValue="previousRisk.resource"
                                :readonly="!HasReadWritePermissions"
                                :showHistory="true"
                                :disabled="activeItem.probability == 0 || activeItem.probability == 6 ? true : false"
                                :largeSlider="false"
                                :maxValue="5"
                              />
                              <v-text-field class="mx-2" flat label="Korriger" dense solo outlined value="1500 t."></v-text-field>
                            </v-col>
                            <v-col cols="1" class="flex-column">
                              <p class="text-center">{{ $t('RiskListHeaders.Status') }}</p>
                              <DoubleSlider
                                class=""
                                :largeSlider="true"
                                :maxValue="6"
                                :disabled="activeItem.probability == 0 || activeItem.probability == 6 ? true : false"
                                :value="
                                  activeItem.probability == 0
                                    ? 0
                                    : activeItem.probability == 6
                                    ? 5
                                    : $util.consequenceCalculator(
                                        activeItem.timeLine,
                                        activeItem.expenses,
                                        activeItem.scope,
                                        activeItem.resource,
                                        activeItem.probability
                                      )
                                "
                                :previousValue="
                                  $util.consequenceCalculator(
                                    previousRisk.timeLine,
                                    previousRisk.expenses,
                                    previousRisk.scope,
                                    previousRisk.resource,
                                    previousRisk.probability
                                  )
                                "
                                :readonly="true"
                                :showHistory="true"
                                :color="
                                  $util.colorizeByMatrix(
                                    $util.consequenceCalculator(
                                      activeItem.timeLine,
                                      activeItem.expenses,
                                      activeItem.scope,
                                      activeItem.resource,
                                      activeItem.probability
                                    ),
                                    project.riskAppetite,
                                    activeItem.probability
                                  )
                                "
                              />
                            </v-col>
                            <v-col cols="3" class="flex-column">
                              <v-row no-gutters>
                                <v-col cols="11" class="">
                                  <div class="font-weight-bold">Mitigering</div>
                                  <v-textarea
                                    height="115"
                                    solo
                                    dense
                                    flat
                                    outlined
                                    no-resize
                                    name="input-7-4"
                                    label="Solo textarea"
                                    value="orem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's "
                                  >
                                  </v-textarea>
                                </v-col>
                                <v-col cols="1" class="flex-column">
                                  <v-btn plain icon large @click="closeRow(item)" :title="$t('ButtonsLabel.CloseRow')"
                                    ><v-icon>mdi-close-circle-outline</v-icon>
                                  </v-btn>
                                  <v-btn plain icon large @click="refreshItem" :disabled="saveIsDisabled" :title="$t('ButtonsLabel.ResetSlider')">
                                    <v-icon>mdi-restart</v-icon>
                                  </v-btn>
                                </v-col>
                              </v-row>
                              <v-row no-gutters>
                                <v-col cols="auto" align-self="center" class="pr-2">
                                  <label class="font-weight-bold">Mitigeringsomkostning</label>
                                </v-col>
                                <v-col cols="auto" align-self="center" class="pr-2">
                                  <v-text-field
                                    class=""
                                    hide-details="true"
                                    dense
                                    outlined
                                    background-color="#ffffff"
                                    value="100.000,00"
                                    style="max-width: 110px"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="auto" class="d-flex align-center justify-end">
                                  <label class="font-weight-bold">I budget</label>
                                  <v-checkbox></v-checkbox>
                                </v-col>
                              </v-row>
                              <v-row no-gutters>
                                <v-col cols="11" class="">
                                  <div class="font-weight-bold">Bemærkninger</div>
                                  <v-textarea
                                    height="110"
                                    solo
                                    dense
                                    flat
                                    outlined
                                    no-resize
                                    name="input-7-4"
                                    label="Solo textarea"
                                    value="orem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's "
                                  >
                                  </v-textarea>
                                </v-col>
                                <v-col cols="1" class="pb-4" align-self="end">
                                  <v-btn
                                    icon
                                    large
                                    plain
                                    :disabled="saveIsDisabled && HasReadWritePermissions"
                                    :title="$t('ButtonsLabel.Save')"
                                    :loading="loading"
                                    @click="save(item)"
                                  >
                                    <v-icon>mdi-content-save-outline</v-icon>
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>

                          <v-row no-gutters class="mt-n2">
                            <v-col cols="2" class="text-left pl-3 mr-14 d-flex">
                              <!-- <v-row no-gutters>
                                <v-col class="d-flex justify-end">
                                  <span class="font-weight-bold mt-6 mr-2">Vis historik</span>
                                  <v-checkbox class=""></v-checkbox>
                                </v-col>
                                <v-col>
                                  <div>Historik</div>
                                  <v-select class="" flat v-model="riskHistorySelection" :items="currrentRiskHistory" dense solo @change="setItem()"></v-select>
                                </v-col>
                              </v-row> -->
                            </v-col>
                            <v-col cols="1"></v-col>
                            <v-col cols="1" class="mt-4">
                              <!-- <v-text-field class="mx-2" flat label="Korriger" dense solo outlined></v-text-field> -->
                              <!-- <v-text-field reverse flat label="" dense solo value="125.000"></v-text-field> -->
                            </v-col>
                            <v-col cols="1" class="mt-4">
                              <!-- <v-text-field class="mx-2" flat label="Korriger" dense solo outlined value="125.000 kr."></v-text-field> -->
                            </v-col>
                            <v-col cols="1" class="mt-4">
                              <!-- <v-text-field class="mx-2" flat label="Korriger" dense solo outlined></v-text-field> -->
                              <!-- <div style="height: 60px">Beregnet</div>
                              <div>Korrigeret</div> -->
                            </v-col>
                            <v-col cols="1" class="mt-4">
                              <!-- <v-text-field class="mx-2" flat label="Korriger" dense solo outlined value="1500 t."></v-text-field> -->
                              <!-- <v-text-field reverse flat label="Solo" dense solo></v-text-field> -->
                            </v-col>
                            <v-col cols="1"></v-col>
                            <v-col cols="3" class="flex-colum">
                              <!-- <div class="font-weight-bold">Bemærkninger</div>
                              <v-textarea
                                height="110"
                                solo
                                dense
                                flat
                                outlined
                                no-resize
                                name="input-7-4"
                                label="Solo textarea"
                                value="orem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500"
                              >
                              </v-textarea> -->
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>

                      <v-row v-if="isExpanded(item) && $vuetify.breakpoint.mobile" no-gutters class="px-4 text-body-2" justify="center" align="center">
                        <!-- phone Top text with divider -->
                        <v-col cols="12" align="center" @click="closeRow">
                          <v-divider class="mb-4"></v-divider>
                          <h2>{{ $t('RiskListHeaders.Probability') }}</h2>
                        </v-col>
                        <!-- phone probability slider -->
                        <v-col align="center" cols="12" class="mt-2">
                          <v-slider
                            class="mt-6"
                            v-model.number="activeItem.probability"
                            min="0"
                            max="6"
                            :color="$util.colorize(activeItem.probability)"
                            :thumb-label="activeItem.probability == 0 || activeItem.probability == 6 ? false : 'always'"
                            thumb-size="24"
                            :readonly="!HasReadWritePermissions"
                            :tick-labels="[$t('RiskListHeaders.WillNotOccur'), '', '', '', '', '', $t('RiskListHeaders.HasOccurred')]"
                          />
                        </v-col>
                        <!-- phone TimePlan slider -->
                        <v-col align="center" cols="10">
                          <p class="mb-0">{{ $t('RiskListHeaders.TimePlan') }}</p>
                          <v-slider
                            hide-details
                            v-model.number="activeItem.timeLine"
                            :min="sliderMin"
                            :max="sliderMax"
                            :color="activeItem.probability == 0 || activeItem.probability == 6 ? 'grey' : $util.colorize(activeItem.timeLine)"
                            :thumb-label="false"
                            :tick-labels="[1, 2, 3, 4, 5]"
                            ticks="always"
                            thumb-size="24"
                            :readonly="!HasReadWritePermissions"
                            :disabled="activeItem.probability == 0 || activeItem.probability == 6"
                          >
                          </v-slider>
                        </v-col>
                        <!-- phone Expenses slider -->
                        <v-col align="center" cols="10" class="mt-6">
                          <p class="mb-0">{{ $t('RiskListHeaders.Expenses') }}</p>
                          <v-slider
                            hide-details
                            v-model.number="activeItem.expenses"
                            :min="sliderMin"
                            :max="sliderMax"
                            :color="activeItem.probability == 0 || activeItem.probability == 6 ? 'grey' : $util.colorize(activeItem.expenses)"
                            :thumb-label="false"
                            :tick-labels="[1, 2, 3, 4, 5]"
                            ticks="always"
                            thumb-size="24"
                            :readonly="!HasReadWritePermissions"
                            :disabled="activeItem.probability == 0 || activeItem.probability == 6"
                          >
                          </v-slider>
                        </v-col>
                        <!-- phone scope slider -->
                        <v-col align="center" cols="10" class="mt-6">
                          <p class="mb-0">{{ $t('RiskListHeaders.Scope') }}</p>
                          <v-slider
                            hide-details
                            v-model.number="activeItem.scope"
                            :min="sliderMin"
                            :max="sliderMax"
                            :color="activeItem.probability == 0 || activeItem.probability == 6 ? 'grey' : $util.colorize(activeItem.scope)"
                            :thumb-label="false"
                            :tick-labels="[1, 2, 3, 4, 5]"
                            ticks="always"
                            thumb-size="24"
                            :readonly="!HasReadWritePermissions"
                            :disabled="activeItem.probability == 0 || activeItem.probability == 6"
                          >
                          </v-slider>
                        </v-col>
                        <!-- phone Resources slider -->
                        <v-col align="center" cols="10" class="mt-6">
                          <p class="mb-0">{{ $t('RiskListHeaders.Resources') }}</p>
                          <v-slider
                            hide-details
                            v-model.number="activeItem.resource"
                            :min="sliderMin"
                            :max="sliderMax"
                            :color="activeItem.probability == 0 || activeItem.probability == 6 ? 'grey' : $util.colorize(activeItem.resource)"
                            :thumb-label="false"
                            :tick-labels="[1, 2, 3, 4, 5]"
                            ticks="always"
                            thumb-size="24"
                            :readonly="!HasReadWritePermissions"
                            :disabled="activeItem.probability == 0 || activeItem.probability == 6"
                          >
                          </v-slider>
                        </v-col>
                        <v-col cols="12" class="text-center pa-4 d-block mt-4">
                          <v-btn v-if="HasReadWritePermissions && !saveIsDisabled" :loading="loading" block @click="save(item)">
                            <v-icon class="mr-2">mdi-content-save-outline</v-icon> {{ $t('ButtonsLabel.Save') }}
                          </v-btn>
                          <v-btn class="mt-4" block @click="refreshItem" v-if="!saveIsDisabled">
                            <v-icon class="mr-2">mdi-restart</v-icon> {{ $t('ButtonsLabel.ResetSlider') }}
                          </v-btn>
                          <v-btn class="mt-4" block @click="showRiskDetails(item)">
                            <v-icon class="mr-2">mdi-dots-horizontal</v-icon> {{ $t('ButtonsLabel.SeeDetails') }}
                          </v-btn>
                          <v-btn class="mt-4" block @click="closeRow(item)">
                            <v-icon class="mr-2">mdi-close-circle-outline</v-icon> {{ $t('ButtonsLabel.CloseRow') }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-expand-transition>
                  </v-card>
                </v-expand-transition>
              </v-hover>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>

      <create-edit-risk ref="CreateEditRiskRef" @risk-updated="getProject" />
    </div>
    <risk-details @risk-updated="getProject" v-else />
  </v-container>
</template>

<script>
import ColorRangeField from '@/components/ColorRangeField.vue';
import apiService from '@/services/apiService.js';
import { mapGetters } from 'vuex';
import CreateEditRisk from './Dialogs/CreateEditRisk.vue';
import RiskDetails from './details/RiskDetails.vue';
import _ from 'lodash';
import DoubleSlider from './DoubleSlider.vue';

export default {
  components: {
    ColorRangeField,
    CreateEditRisk,
    RiskDetails, //LeftNavBar
    DoubleSlider,
  },

  data: () => ({
    riskHistory: [],
    currrentRiskHistory: [],
    riskHistorySelection: [],
    riskTypeSelectedItem: 'Interessenter',
    activeCount: 0,
    disabletCount: 0,
    previousRisk: [],
    expandNewRisk: true,
    expandSearch: false,
    riskList: [],
    newestRisks: [],
    oldRisks: [],
    activeItem: null,
    riskName: '',
    sliderMin: 1,
    sliderMax: 5,
    loading: true,
    expanded: [],
    singleExpand: true,
    project: {},
    idOfSelected: null,
    selselectedItem: 0,
    Search: '',
    expand: false,
    searchMode: false,
    riskActiveselected: false,
    riskDisabletselected: false,
    AdminOrIsInSameCompany: false,
    updateHideDisabledRisks: false,
    updateHideActiveRisks: false,
    footerProps: { 'items-per-page-options': [15, 30, 50, 100] },
  }),
  watch: {
    $route: 'getProject',
    currentProjectId: 'getProject',

    updateHideDisabledRisks(val) {
      if (val == true) {
        if (this.updateHideActiveRisks == true) {
          this.updateHideActiveRisks = false;
        }
      }
    },
    updateHideActiveRisks(val) {
      if (val == true) {
        if (this.updateHideDisabledRisks == true) {
          this.updateHideDisabledRisks = false;
        }
      }
    },
  },
  computed: {
    ...mapGetters(['currentProjectId', 'isManager', 'isAdmin', 'darkMode', 'user', 'projectList', 'hideDisabledRisks']),
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },

    CalculateAvarageNewProbility() {
      const result = this.getAvarageResult(false, 'Probility');
      return result;
    },

    CalculateAvarageOldProbility() {
      const result = this.getAvarageResult(true, 'Probility');
      return result;
    },

    CalculateAvarageNewTimeLine() {
      const result = this.getAvarageResult(false, 'TimeLine');
      return result;
    },

    CalculateAvarageOldTimeLine() {
      const result = this.getAvarageResult(true, 'TimeLine');
      return result;
    },

    CalculateAvarageNewExpenses() {
      const result = this.getAvarageResult(false, 'Expenses');
      return result;
    },

    CalculateAvarageOldExpenses() {
      const result = this.getAvarageResult(true, 'Expenses');
      return result;
    },

    CalculateAvarageNewScope() {
      const result = this.getAvarageResult(false, 'Scope');
      return result;
    },

    CalculateAvarageOldScope() {
      const result = this.getAvarageResult(true, 'Scope');
      return result;
    },

    CalculateAvarageNewResource() {
      const result = this.getAvarageResult(false, 'Resource');
      return result;
    },

    CalculateAvarageOldResource() {
      const result = this.getAvarageResult(true, 'Resource');
      return result;
    },

    CalculateAvarageNewStatus() {
      const result = this.getAvarageResult(false, 'Status');
      return result;
    },

    CalculateAvarageOldStatus() {
      const result = this.getAvarageResult(true, 'Status');
      return result;
    },

    RiskListItems() {
      const hideDisabled = this.updateHideDisabledRisks;
      const hideActive = this.updateHideActiveRisks;
      const search = this.Search.toLowerCase();
      let risksReturn;
      if (hideDisabled) {
        risksReturn = this.newestRisks.filter((risk) => risk.probability != 0 && risk.probability != 6);
      } else if (hideActive) {
        risksReturn = this.newestRisks.filter((risk) => risk.probability == 0 || risk.probability == 6);
      } else {
        risksReturn = this.newestRisks;
      }

      if (search !== '') {
        risksReturn = risksReturn.filter(
          (item) =>
            item.owner.name?.toLowerCase().includes(search) ||
            item.title?.toLowerCase().includes(search) ||
            item.mitigation?.toLowerCase().includes(search) ||
            item.consequenceDescription?.toLowerCase().includes(search) ||
            item.version?.toString().includes(search) ||
            item.expenses?.toString().includes(search) ||
            item.probability?.toString().includes(search) ||
            item.resource?.toString().includes(search) ||
            item.riskNumber?.toString().includes(search) ||
            item.scope?.toString().includes(search) ||
            item.timeLine?.toString().includes(search)
        );
        return risksReturn;
      } else {
        return risksReturn;
      }
    },

    getSortOptions() {
      const t = this.$t.bind(this);
      const items = [
        { text: t('RiskListHeaders.Risk'), id: 0 },
        { text: t('RiskListHeaders.Probability'), id: 1 },
        { text: t('RiskListHeaders.TimePlan'), id: 2 },
        { text: t('RiskListHeaders.Expenses'), id: 3 },
        { text: t('RiskListHeaders.Scope'), id: 4 },
        { text: t('RiskListHeaders.Resources'), id: 5 },
        { text: t('RiskListHeaders.Owner'), id: 6 },
        { text: t('RiskListHeaders.OverallConsequences'), id: 7 },
      ];
      if (this.idOfSelected !== null) {
        for (let index = 0; index < items.length; index++) {
          if (items[index].id === this.idOfSelected) {
            this.setSelectedOnLangaugeChange(items[index].text);
            break;
          }
        }
      }
      return items;
    },

    detailsView() {
      return this.$route.params.riskId;
    },
    HasReadWritePermissions() {
      if (this.isAdmin) {
        return true;
      }
      if (this.$util.HasReadAndWrite(this.user, this.project.permissions) == false) {
        return false;
      } else if (this.AdminOrIsInSameCompany) {
        if (this.isManager) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    HasFullAccessPermissions() {
      if (this.isAdmin) {
        return true;
      }
      if (this.$util.HasFullAccess(this.user, this.project.permissions) == false) {
        return false;
      } else if (this.AdminOrIsInSameCompany) {
        if (this.isManager) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    saveIsDisabled() {
      if (this.activeItem != null) {
        const data = this.riskList.find((x) => x.id == this.activeItem.id);
        if (
          data.probability != this.activeItem.probability ||
          data.resource != this.activeItem.resource ||
          data.scope != this.activeItem.scope ||
          data.timeLine != this.activeItem.timeLine ||
          data.expenses != this.activeItem.expenses
        ) {
          return false;
        } else {
          return true;
        }
      }
      return true;
    },
  },
  methods: {
    getHistory(risk_OriginalId) {
      this.currrentRiskHistory = [];
      apiService.getRiskHistory(risk_OriginalId).then((data) => {
        this.riskHistory = data;
        for (let i = 0; i < data.length; i++) {
          this.currrentRiskHistory.push({
            value: data[i].id,
            text: this.$util.formatDate(data[i].created, true),
          });

          //this.currrentRiskHistory.push(this.$util.formatDate(data[i].created, true));
        }

        //console.log(this.currrentRiskHistory[0].text);

        this.currrentRiskHistory.reverse();

        this.riskHistorySelection = this.currrentRiskHistory[0];
      });
    },
    changeSearchNewRisk(flag) {
      if (flag) {
        this.expandSearch = true;
        this.expandNewRisk = false;
      } else {
        if (this.expandSearch == false) {
          this.addRisk();
        } else {
          this.expandSearch = false;
          this.expandNewRisk = true;
        }
      }
    },
    updateActiveDisabledRisks() {
      if (this.riskDisabletselected) {
        this.updateHideActiveRisks = true;
      } else if (this.riskActiveselected) {
        this.updateHideDisabledRisks = true;
      } else {
        this.updateHideDisabledRisks = false;
        this.updateHideActiveRisks = false;
      }
    },
    expandRow(item) {
      this.activeItem = _.cloneDeep(item);
      this.expanded.includes(this.activeItem) ? (this.expanded = []) : ((this.expanded = []), this.expanded.push(this.activeItem));
      var previousRiskTemp = this.riskList.filter((x) => x.riskNumber == item.riskNumber);
      this.previousRisk = previousRiskTemp[previousRiskTemp.length - 1];
      this.getHistory(this.activeItem.riskOriginalId);
    },
    closeRow(item) {
      this.activeItem = null;
      this.expanded.includes(item) ? (this.expanded = []) : ((this.expanded = []), this.expanded.filter((x) => x == item));
    },
    refreshItem() {
      const findItem = this.riskList.find((x) => x.id == this.activeItem.id);
      this.activeItem.probability = findItem.probability;
      this.activeItem.timeLine = findItem.timeLine;
      this.activeItem.expenses = findItem.expenses;
      this.activeItem.scope = findItem.scope;
      this.activeItem.resource = findItem.resource;
    },
    setItem() {
      const findItem = this.riskHistory.find((x) => x.id == this.riskHistorySelection);
      this.activeItem.probability = findItem.probability;
      this.activeItem.timeLine = findItem.timeLine;
      this.activeItem.expenses = findItem.expenses;
      this.activeItem.scope = findItem.scope;
      this.activeItem.resource = findItem.resource;
    },
    activeOrItemStatus(isExpanded, item) {
      if (isExpanded == true) {
        return this.$util.consequenceCalculator(
          this.activeItem.timeLine,
          this.activeItem.expenses,
          this.activeItem.scope,
          this.activeItem.resource,
          this.activeItem.probability
        );
      } else {
        return this.$util.consequenceCalculator(item.timeLine, item.expenses, item.scope, item.resource, item.probability);
      }
    },

    getAvarageResult(isOld, dataToGet) {
      let returnData;
      let ListWithNonActive;
      if (isOld) ListWithNonActive = this.oldRisks.filter((x) => x.probability != 0 && x.probability != 6);
      else ListWithNonActive = this.newestRisks.filter((x) => x.probability != 0 && x.probability != 6);

      if (dataToGet == 'Probility') {
        let sum = _.sumBy(ListWithNonActive, 'probability');
        returnData = Math.round(sum / ListWithNonActive.length);
      }

      if (dataToGet == 'TimeLine') {
        let sum = _.sumBy(ListWithNonActive, 'timeLine');
        returnData = Math.round(sum / ListWithNonActive.length);
      } else if (dataToGet == 'Expenses') {
        let sum = _.sumBy(ListWithNonActive, 'expenses');
        returnData = Math.round(sum / ListWithNonActive.length);
      } else if (dataToGet == 'Scope') {
        let sum = _.sumBy(ListWithNonActive, 'scope');
        returnData = Math.round(sum / ListWithNonActive.length);
      } else if (dataToGet == 'Resource') {
        let sum = _.sumBy(ListWithNonActive, 'resource');
        returnData = Math.round(sum / ListWithNonActive.length);
      } else if (dataToGet == 'Status') {
        const Status = ListWithNonActive.map((x) => this.$util.consequenceCalculator(x.timeLine, x.expenses, x.scope, x.resource, x.probability));
        let sum = 0;
        for (let i = 0; i < Status.length; i++) {
          sum += Status[i];
        }
        returnData = Math.round(sum / Status.length);
      }
      if (isNaN(returnData)) {
        returnData = 0;
      }
      return returnData;
    },
    async getProjects() {
      if (this.user) {
        this.selectedItem = this.currentProjectId;
        let projects = await apiService.getProjects();

        this.$store.dispatch('SET_PROJECTLIST', projects);
        if (!this.selectedItem) {
          this.selectedItem = (await projects) ? projects[0].id : null;
          this.$store.dispatch('SET_PROJECT_ID', this.selectedItem);
        }
      }
    },
    async getProject() {
      this.loading = true;
      this.project = await apiService.getProjectById(this.currentProjectId);

      this.riskList = await apiService.getLatestTwoRisks(this.currentProjectId);

      this.newestRisks = this.riskList.filter((risk) => risk.commitStatus == 2);

      this.oldRisks = this.riskList.filter((risk) => risk.commitStatus == 3);
      // Set active/disabled risk count
      this.activeCount = this.newestRisks.filter((risk) => risk.probability != 0 && risk.probability != 6).length.toString();
      this.disabletCount = this.newestRisks.filter((risk) => risk.probability == 0 || risk.probability == 6).length.toString();
      if (this.$vuetify.breakpoint.mobile) {
        this.getProjects();
      }

      this.IsAdminOrIsInSameCompany();
      this.loading = false;
    },
    async IsAdminOrIsInSameCompany() {
      if (this.isAdmin) {
        this.AdminOrIsInSameCompany = true;
      } else if (this.project.companyId != null) {
        if (this.project.companyId == this.user.companyId) {
          this.AdminOrIsInSameCompany = true;
        } else {
          this.AdminOrIsInSameCompany = false;
        }
      } else {
        this.AdminOrIsInSameCompany = false;
      }
    },
    showRiskDetails(risk) {
      this.$router.push({
        name: 'RiskDetails',
        params: { riskId: risk.riskOriginalId },
      });
    },
    async save() {
      this.loading = true;
      await apiService.putRisk(this.activeItem);
      this.getProject();
    },
    async addRisk() {
      if (this.riskName.trim() == '') {
        this.$refs.CreateEditRiskRef.createRisk();
      } else {
        this.addQuickRisk();
      }
    },
    async addQuickRisk() {
      this.currentRisk = {
        title: this.riskName,
        consequenceDescription: '',
        type: 7,
        probability: 0,
        riskStatus: 0,
        owner: this.user,
        ownerId: this.user.id,
        commitStatus: 2,
        projectId: this.currentProjectId,
      };

      await apiService
        .putRisk(this.currentRisk)
        .then(() => {
          this.show = false;
          this.riskName = '';
          this.getProject().then(() => {
            this.riskList.sort((a, b) => a.updated - b.updated);
            this.expandRow(this.riskList[this.riskList.length - 1]);
          });
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
  },
  created() {
    this.getProject();
    if (this.$vuetify.breakpoint.mobile) {
      this.getProjects();
    }
  },
  mounted() {},
};
</script>

<style>
.large-slider .v-slider {
  height: 225px !important;
}
.small-slider .v-slider {
  height: 185px !important;
}
.v-input__control .v-input__slot .v-select__slot > label {
  transform: translateY(-20px) scale(0.75) !important;
}

.v-slider__thumb-label {
  color: black !important;
}
.v-input--selection-controls__input :hover {
  background-color: none !important;
  background: none !important;
  box-shadow: 0 !important;
}
</style>