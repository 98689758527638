<template>
  <div class="mt-n2" style="height: 250px; width: 0px; margin-left: auto; margin-right: auto">
    <v-slider
      v-if="showHistory"
      style="position: absolute; z-index: 0"
      hide-details
      :min="0"
      :max="maxValue"
      :vertical="!isMobile"
      :class="largeSlider ? 'large-slider background_slider' : 'small-slider background_slider mt-2'"
      thumb-size="24"
      :thumb-label="isMobile ? false : false"
      :tick-labels="isMobile ? [1, 2, 3, 4, 5] : []"
      ticks="always"
      :value="previousValue"
      track-color="#B5B6B8"
      track-fill-color="#B5B6B8"
      thumb-color="#B5B6B8"
      disabled
      v-bind="$attrs"
    >
    </v-slider>
    <v-slider
      :class="largeSlider ? 'large-slider' : 'small-slider mt-2'"
      style="position: absolute; z-index: 1"
      hide-details
      v-model="sliderValue"
      :min="0"
      :max="maxValue"
      :color="color ? color : $util.colorize(value)"
      :thumb-label="isMobile ? false : 'always'"
      :tick-labels="isMobile ? [1, 2, 3, 4, 5] : []"
      thumb-size="30"
      :vertical="!isMobile"
      ticks="always"
      v-bind="$attrs"
      track-color="#B5B6B8"
      track-fill-color="#B5B6B8"
    >
    </v-slider>
  </div>
</template>

<script>
export default {
  name: 'DoubleSlider',
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    sliderValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  props: {
    value: Number,
    previousValue: Number,
    showHistory: Boolean,
    color: String,
    largeSlider: Boolean,
    maxValue: Number,
  },
};
</script>
